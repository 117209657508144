import api from '@/plugins/api';

export async function getClientInfo() {
    const { data: { client, projects, client_template } } = await api.get('user/client');

    return { client, projects, client_template };
}

export async function getTemplates() {
    const { data: templates } = await api.get('user/templates');

    return templates;
}

export async function getAllFields({ project, template }) {
    let { projects } = await getClientInfo();
    if (projects.length === 0) {
        throw new Error('No se han encontrado proyectos habilitados para el usuario');
    }
    let { data: { data: { sections } } } = await api.get(`projects/${project}/templates/${template}`);

    let fields = {};

    for (const section of sections) {
        for (const groups of section.groups) {
            for (const group of groups) {
                for (const field of group.fields) {
                    fields[field.tag] = field;
                }
            }
        }
        for (const subsection of section.subsections) {
            for (const group of subsection.groups) {
                for (const field of group.fields) {
                    fields[field.tag] = field;
                }
            }
        }
    }

    return fields;
}