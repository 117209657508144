const client = {
    namespaced: true,
    state: {
        name: "",
        logo: "",
        projects: [],
        templates: []
    },
    mutations: {
        setClient(state, { client, projects }) {
            state.name = client.name;
            state.logo = client.logo;
            state.projects = projects;
        },
        setTemplates(state, templates) {
            state.projects = templates;
        },
    },
    actions: {
        setClient({ commit }, { client, projects }) {
            commit('setClient', { client, projects });
        },
        setTemplates({ commit }, templates) {
            commit('setTemplates', templates);
        },
    },
    getters: {
        getClient: (state) => {
            return state;
        },
        getProjects: (state) => {
            return Object.values(state.projects);
        },
        getTemplate: (state) => (project) => {
            return state.projects[project]?.templates.filter(e => e.parent == 261 || e.id == 366) || [];
        }
    }
}

export default client;