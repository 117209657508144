import api from '@/plugins/api';
import { ToastProgrammatic as Toast } from 'buefy'

const auth = {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || null
    },
    mutations: {
        login(state, token) {
            localStorage.setItem('token', token);
            state.token = token;
        },
        logout(state) {
            localStorage.removeItem('token');
            state.token = null;
        }
    },
    actions: {
        async authenticate({ commit }, { login_hash }) {
            const { data: { token } } = await api.get(`covid/oz/${login_hash}`);
            if (token) {
                commit('login', token);
                return;
            }
            Toast.open({ type: 'is-danger', message: 'Error en el ingreso' });
            return new Error();
        },
        async login_user({ commit }, { username, password }) {
            const { data: { response, token } } = await api.post('login', { username, password });
            if (response === 'OK') {
                commit('login', token);
                return;
            }
            Toast.open({ type: 'is-danger', message: 'Error en el ingreso' });
            return new Error();
        },
        async logout({ commit }) {
            commit('logout');
        }
    },
    getters: {
        isLogged: (state) => state.token !== null
    }
}

export default auth