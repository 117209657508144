import axios from 'axios';

const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

let token = localStorage.getItem('token');
if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

api.interceptors.request.use((config) => {
    let token = localStorage.getItem('token');

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
})

export default api;