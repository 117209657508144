import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      if (store.getters['auth/isLogged']) {
        next({ name: 'Formulario' })
        return;
      }
      next()
    }
  },
  {
    path: '/formulario',
    name: 'Formulario',
    component: () => import(/* webPackChunkName: "form" */ '@/views/Formulario.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dorothy/:magic',
    name: 'Dorothy',
    beforeEnter: async (to, from, next) => {
      if (store.getters['auth/isLogged']) {
        next({ name: 'Formulario' })
        return;
      }
      next()
      try {
        await store.dispatch('auth/authenticate', { login_hash: to.params.magic });
        if (store.getters['auth/isLogged']) {
          next({ name: 'Formulario' });
          return;
        }
        next({ name: 'Login' });
      }
      catch (e) {
        next({ name: 'Login' });
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLogged']) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

export default router
