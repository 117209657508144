import Vue from 'vue';
import { getAllFields } from '@/plugins/form';

const form = {
    namespaced: true,
    state: {
        answers: {},
        images: {},
        fields: {}
    },
    mutations: {
        saveAnswer(state, { item, value, tagResponse = null, tag: fieldTag }) {
            let tag = tagResponse !== null ? tagResponse : 0;
            if (state.answers[tag] === undefined) {
                Vue.set(state.answers, tag, {});
            }
            Vue.set(state.answers[tag], item, { item, id: item, value, tagResponse, tag: fieldTag });
        },
        saveImage(state, { item, images, tagResponse }) {
            let tag = tagResponse !== null ? tagResponse : 0;
            if (state.images[tag] === undefined) {
                Vue.set(state.images, tag, {});
            }
            Vue.set(state.images[tag], item, { item, images, tagResponse });
        },
        clearAnswers(state) {
            let project = (state.answers[0] || {})._project || {};
            state.answers = { 0: { _project: project } };
            state.images = {}
        },
        saveFields(state, fields) {
            state.fields = fields;
        }
    },
    actions: {
        async setAnswer({ commit }, item) {
            commit('saveAnswer', item);
        },
        setImages({ commit }, { item, images, tagResponse }) {
            commit('saveImage', { item, images, tagResponse })
        },
        clearAnswers({ commit }) {
            commit('clearAnswers');
        },
        async setFields({ commit, getters }) {
            let project = getters.getAnswer('_project')?.value;
            let template = getters.getAnswer('_template')?.value;

            let fields = project && template ? await getAllFields({ project, template }) : {};

            commit('saveFields', fields);
        }
    },
    getters: {
        allAnswers: (state) => {
            let answers = [];
            for (const subitems of Object.values(state.answers)) {
                Object.values(subitems).forEach(e => answers.push(e));
            }
            return answers;
        },
        getAnswer: (state, getters) => (fid, tagResponse = null) => {
            let answers = getters.allAnswers;
            return Object.values(answers).find(e => e.item == fid && tagResponse == e.tagResponse);
        },
        getCollectorByDosis: (state, getters) => (dosis) => {
            let answers = getters.allAnswers;
            return Object.values(answers).find(e => e.value == dosis)?.tagResponse;
        },
        allImages: (state) => {
            let answers = [];
            for (const subitems of Object.values(state.images)) {
                Object.values(subitems).forEach(e => answers.push(e));
            }
            return answers;
        },
        hasAnswer: (state, getters) => (fid, tagResponse = null) => {
            let answers = getters.allAnswers;
            return Object.values(answers).find(e => e.item == fid && tagResponse == e.tagResponse)?.value || false;
        },
        getFields: (state) => {
            return state.fields;
        }
    }
}

export default form;